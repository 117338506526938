export const CARD_HOLDER_MOCK = 'Card Holder'
export const CVC_MOCK = '123'

interface IObjectData {
    [key: string]: string | undefined
}

export function removeEmptyRecordsFromObject(data: IObjectData) {
    for (const key in data) {
        if (!data[key]) {
            delete data[key]
        } else {
            data[key] = data[key]?.trim()

            if (!data[key]) {
                delete data[key]
            }
        }
    }

    return data
}

export function getPreferredLanguage() {
    const language = window.navigator.language

    return language.split('-')[0]
}

export const isEmptyObject = (object?: Record<string, string> | null | unknown) => {
    if (!object) {
        return true
    }

    return !Object.keys(object).length
}

export function createRequestId() {
    return window.location.origin + '_ge_pay_' + Date.now()
}

export function addCommas(n: string){
    const rx=  /(\d+)(\d{3})/

    return String(n).replace(/^\d+/, function(w){
        while(rx.test(w)){
            w= w.replace(rx, '$1,$2')
        }

        return w
    })
}
