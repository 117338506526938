import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import PayService from '../../services/pay/pay.service'
import { CARD_HOLDER_MOCK, isEmptyObject, removeEmptyRecordsFromObject } from '../../common/utils'
import { PaymentMethodEnum } from '../../enums/paymentMethod.enum'
import getExtraData from '../../common/getExtraData'
import { ISubmitVoidServicePayload, IValidateCardResponse } from '../../services/pay/pay.interface'
import {
    ICustomerInfoPayload,
    ISubmitCardFormPayload,
    ISubmitVoidActionPayload
} from '../interface/payAction.interface'
import { IPayInfoData } from '../../interfaces/PayInfoResponse'
import { IValidateCardDatePayload } from '../../interfaces/ValidateCardDatePayload'
import { ISubmitUserActionPayload } from '../../interfaces/SubmitUserActionPayload'

const setPayInfo = createAction<IPayInfoData>('pay/setPayInfo')
const setValidationInfo = createAction<IValidateCardResponse>('pay/setValidationInfo')

const getPayInfoAction = createAsyncThunk(
    'pay/getPayInfo',
    async (payload: string, thunkApi) => {
        try {
            const response = await PayService.getPayInfo(payload)
            thunkApi.dispatch(setPayInfo(response.data))

            return response.data
        } catch (error) {
            console.error(error)
        }
    }
)

const validateCardDateExpire = createAsyncThunk(
    'pay/validateCardDateExpire',
    async (payload: IValidateCardDatePayload, thunkApi) => {
        try {
            const {
                id,
                value
            } = payload
            const body = {
                bin: value.slice(0, 6)
            }
            const response = await PayService.validateCardDateExpire(id, body)
            thunkApi.dispatch(setValidationInfo(response.data))

            return response.data
        } catch (error) {
            console.error(error)
        }
    }
)

const submitCardForm = createAsyncThunk(
    'pay/submitCardForm',
    async (payload: ISubmitCardFormPayload) => {
        try {
            const {
                id,
                data: {
                    extra,
                    cardInfo,
                    payInfo,
                    submittedInfo,
                    code3ds,
                    kushkiJwt,
                    deviceSessionId
                }
            } = payload

            const info: ICustomerInfoPayload = {}
            if (submittedInfo && !isEmptyObject(submittedInfo)) {
                Object.keys(submittedInfo).forEach(key => {
                    const upperCasedKey = key[0].toUpperCase() + key.slice(1, key.length)
                    info[upperCasedKey] = submittedInfo[key]
                })
            }

            const CustomerInfo = {
                'Country': info?.Country || payInfo?.customer_info?.Country?.toUpperCase(),
                'Language': payInfo?.customer_info?.Language,
                'Town': info?.Town || payInfo?.customer_info?.Town,
                'Address': info?.Address || payInfo?.customer_info?.Address,
                'ZIP': info?.ZipCode || info?.ZIP || payInfo?.customer_info?.ZIP,
                'Phone': info?.Phone || payInfo?.customer_info?.Phone,
                'Email': info?.Email || payInfo?.customer_info?.Email,
                'DateOfBirth': info?.DateOfBirth || payInfo?.customer_info?.DateOfBirth,
                'FirstName': info?.FirstName || payInfo?.customer_info?.FirstName,
                'LastName': info?.LastName || payInfo?.customer_info?.LastName,
                'TypeDocument': info?.TypeDocument || payInfo?.customer_info?.TypeDocument
            }

            const {
                expDate
            } = cardInfo
            const cardMonth = expDate.slice(0,2)
            const cardYear = expDate.slice(2, expDate.length)

            const PaymentDetails = {
                'CardholderName': cardInfo.cardHolder.trim().length ? cardInfo.cardHolder : CARD_HOLDER_MOCK,
                'CVC': cardInfo.cvc,
                'CardNumber': cardInfo.cardNum,
                'ExpMonth': cardMonth,
                'ExpYear': cardYear
            }
            const extraData = getExtraData()
            const ExtraData = {
                PersonalId: info?.PersonalId,
                AddressNumber: info?.AddressNumber,
                AddressStreet: info?.AddressStreet,
                RFC: info?.RFC,
                State: info?.State,
                ...extraData,
                ...extra
            }

            if (code3ds) {
                ExtraData.code3ds = code3ds
            }

            if (kushkiJwt) {
                ExtraData.kushkiJwt = kushkiJwt
            }

            if (deviceSessionId) {
                ExtraData.deviceSessionId = deviceSessionId
            }

            const body = {
                'CustomerInfo': removeEmptyRecordsFromObject(CustomerInfo),
                'PaymentMethod': PaymentMethodEnum.Card,
                'PaymentDetails': removeEmptyRecordsFromObject(PaymentDetails),
                'RebillFlag': payInfo?.request?.RebillFlag as boolean,
                'ExtraData': ExtraData,
                'ReceiptData': payInfo?.receipt_data
            }
            const response = await PayService.submitCardForm(id, body)

            return response.data
        } catch (error) {
            console.error(error)
        }
    }
)

const submitUserAction = createAsyncThunk(
    'pay/submitUserAction',
    async (payload: ISubmitUserActionPayload) => {
        try {
            const {
                id,
                data
            } = payload
            const body = {
                resultObject: data,
                ExtraData: getExtraData()
            }
            const response = await PayService.submitUserAction(id, body)

            return response.data
        } catch (error) {
            console.error(error)
        }
    }
)

const submitVoid = createAsyncThunk(
    'pay/submitVoid',
    async (payload: ISubmitVoidActionPayload) => {
        try {
            const {
                id,
                data: {
                    customerInfo,
                    extra,
                    bank
                }
            } = payload

            const CustomerInfo = {
                'Country': customerInfo?.Country?.toUpperCase(),
                'Language': customerInfo?.Language,
                'Town': customerInfo?.Town,
                'Address': customerInfo?.Address,
                'ZIP': customerInfo?.ZIP,
                'Phone': customerInfo?.Phone,
                'Email': customerInfo?.Email,
                'DateOfBirth': customerInfo?.DateOfBirth,
                'FirstName': customerInfo?.FirstName,
                'LastName': customerInfo?.LastName,
                'TypeDocument': customerInfo?.TypeDocument,
                'AccountNumber': customerInfo?.AccountNumber,
                'AccountVerificationCode': customerInfo?.AccountVerificationCode,
                'BankName': customerInfo?.BankName,
                'SupermarketCode': customerInfo?.SupermarketCode
            }

            const body: ISubmitVoidServicePayload = {
                ExtraData: extra ? { ...extra, ...getExtraData() } : getExtraData(),
                CustomerInfo: removeEmptyRecordsFromObject(CustomerInfo)
            }

            if (bank) {
                body.bankInfo = bank as unknown as Record<string, string>
            }

            const response = await PayService.submitVoid(id, body)

            return response.data
        } catch (error) {
            console.error(error)
        }
    }
)

const submitVoidNoBody = createAsyncThunk(
    'pay/submitVoidNoBody',
    async (payload: { id: string }) => {
        try {
            const {
                id
            } = payload
            const response = await PayService.submitVoidNoBody(id)

            return response.data
        } catch (error) {
            console.error(error)
        }
    }
)

export default {
    setPayInfo,
    setValidationInfo,
    getPayInfoAction,
    validateCardDateExpire,
    submitCardForm,
    submitVoid,
    submitVoidNoBody,
    submitUserAction
}
