import { createReducer } from '@reduxjs/toolkit'
import { AxiosHeaders } from 'axios'

import { TransactionStateEnum } from '../../enums/transactionState.enum'
import wsAction from '../action/wsAction'

type IInitialState = {
    payment_id: string | null
    state_data: {
        state?: TransactionStateEnum
        url?: string
        payload?: Record<string, string>
        html?: string
        barcode?: {
            message?: string
            barcode?: string
        }
        pdf?: {
            pdfUrl?: string
            message?: string
        }
        qr_code?: {
            copiacola?: string
            copiacola_message?: string
            message?: string
            imgUrl?: string
            imgData?: string
            payload_mobile?: string
        }
        text_info?: {
            message?: string
        }
        iframes: Array<{
            url: string
            method: string
            form_data: Record<string, string>
            delay: number
        }>
        challenge_data?: {
            acs_url?: string
            creq?: string
            threeDSSessionData?: string
            is_send_all?: string
            authentication_transaction_id?: string
        }
        MD?: string
        OTPForm?: Record<string, unknown>
        is_async?: boolean
        tds_pares?: string
        tds_pareq?: string
        tds_md?: string
        tds_submit_data?: {
            acs_url: string
            method: string
            data: Record<string, string>
        }
        redirect_url?: string
        tds_term_url?: string
        tds_acs_url?: string
        userAction?: {
            documents?: Array<{
                labels: string
                file_type: string
            }>
            bankChoices?: Array<unknown>
            providerChoices?: Array<unknown>
            bank_transfer?: {
                expire_date: Date
                account_number: string
                amount: string
                bank_name: string
                currency: string
            }
            data?: {
                merchantId: string
                inTestEnvironment: boolean
                amount: string
                currency: string
                cardHolder: string
                cardPan: string
                cardCvc: string
                cardMonth: string
                cardYear: string
                type: string
                information: string
                country: string
                phone: string
                request: {
                    url: string
                    headers: AxiosHeaders
                    body: {
                        transaction_reference: string
                    }
                } | null
            }
            otp?: {
                country?: string
                amount?: string
                currency?: string
            }
        }
        error_message?: string
        secureId?: string
        security?: {
            paReq: string
            acsURL: string
            authRequired: string
            specificationVersion: string
            authenticationTransactionId: string
        }
    } | null
    [key: string]: string | undefined | null | unknown
}

const initialState: IInitialState = {
    payment_id: null,
    state_data: null
}

interface IAction {
    payload: {
        [key: string]: string | undefined
    }
}

const wsReducer = createReducer(initialState, builder => {
    builder
        .addCase(wsAction.setWsInfo, (state, action: unknown) => {
            Object.keys((action as IAction).payload).map((key: string) => {
                state[key] = (action as IAction).payload[key]
            })
        })
})

export default wsReducer
